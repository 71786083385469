<template>
  <div class="competitor-logo" :class="classes">
    <img v-if="logo" data-t="logo-img" :src="logo" alt="competitor logo" />
    <StIcon v-else data-t="logo-icon" name="t-shirt-solid" :size="iconSize" />
  </div>
</template>

<script setup lang="ts">
import type { TCompetitor } from 'markets-store'

interface Props {
  competitor: TCompetitor
  logoSize?: 'big' | 'medium'
}

const SIZES = {
  logo: {
    big: '52px',
    medium: '36px',
  },
  icon: {
    big: 40,
    medium: 30,
  },
}

const props = withDefaults(defineProps<Props>(), {
  logoSize: 'big',
})

const logo = computed(() => props.competitor.img?.[props.logoSize])
const iconSize = computed(() => SIZES.icon[props.logoSize])
const iconSizePx = computed(() => `${iconSize.value}px`)
const logoSize = computed(() => SIZES.logo[props.logoSize])

const classes = computed(() => [
  `qualifier-${props.competitor?.qualifier ?? 'home'}`,
  `size-${props.logoSize}`,
])
</script>

<style scoped>
.competitor-logo {
  display: flex;
  align-items: center;
  justify-content: center;

  width: v-bind(logoSize);
  height: v-bind(logoSize);
  padding: 3px; /* stylelint-disable-line */

  background: var(--background-ghost-primary);
  backdrop-filter: blur(5px);

  &.size-medium {
    border-radius: var(--border-radius-100);
  }

  &.size-big {
    border-radius: var(--border-radius-150);
  }

  &.qualifier-home {
    color: var(--palette-brand-500);
  }

  &.qualifier-away {
    color: var(--palette-light-1000);
  }

  img {
    display: block;
    width: v-bind(iconSizePx);
    height: auto;
  }
}
</style>
